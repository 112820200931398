import useContextFilters from '../components/hooks/useContextFilters';

const useGetStartupUrl = () => {
    const { paramFormatter } = useContextFilters();

    const getStartupUrl = (name, category, subcategory) => {
        const urlPieces = [category, subcategory, name];
        let startupUrl = '';
        urlPieces.map(piece => {
            startupUrl += `/${paramFormatter(piece)}`;
        })

        return startupUrl;
    }
    return getStartupUrl;
}

export default useGetStartupUrl;