import { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@emotion/react';
import Img from 'react-cool-img';
import "../../../public/png/placeholder.png"

import useGetCategorization from '../../hooks/useGetCategorization';
import useContextFilters from '../../hooks/useContextFilters';
import { theme } from '../../../constants/muiThemeBreakpoints';

export default function Startup({ ...props }) {
  const startupUrl = props.getStartupUrl(props.info.name, props.info.category, props.info.subcategory);
  const { category, subcategory, getCategorization } = useGetCategorization();
  const { goToCategory, goToSubcategory } = useContextFilters();

  useEffect(() => {
    getCategorization(props.info?.category, props.info?.subcategory);
  }, [props.info])

  const routerToStartup = () => {
    props.routerTo(startupUrl);
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={6} sm={6} md={4} lg={4} xl={4} className='company-grid'>
        <div className="featured-company-container" onClick={() => routerToStartup()}>
          <Img
            className='company-logo'
            src={props.info.image}
            placeholder={'/png/placeholder.png'}
            error={'/png/placeholder.png'}
            alt={`${props.info.name} logo`}
          />
          <div className="featured-company-info">
            <p className='capitalized-name'>{props.info.name}</p>
            <button className='button-filled featured-company-category' onClick={(event) => { event.stopPropagation(); goToCategory(category) }}>{category?.name}</button>
            <button className='button-filled featured-company-subcategory' onClick={(event) => { event.stopPropagation(); goToSubcategory(category, subcategory) }}>{subcategory?.name}</button>
          </div>
        </div>
      </Grid >
    </ThemeProvider>
  );
}
