import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import NextHead from '../components/header/nextHead';
import Home from '../components/home/home'
import CookiesBanner from '../components/legals/cookiesBanner';
import useGetStartupUrl from '../hooks/useGetStartupsUrl';
import useNextRouterHook from '../hooks/useNextRouterHook'

export default function HomePage() {
  const routerTo = useNextRouterHook();
  const getStartupUrl = useGetStartupUrl();

  return (
    <div>
      <NextHead
        title={'Proptechpedia: encuentra la empresa Proptech que buscas.'}
        description={'Proptechpedia nace con el objetivo de ser un espacio para el sector inmobiliario donde las empresas Proptech nacionales puedan dar a conocer sus productos y servicios y los profesionales del sector encuentren todas las soluciones existentes en el mercado para transformar sus procesos de negocio.'}
      />
      <Header routerTo={routerTo} />
      <main>
        <Home routerTo={routerTo} getStartupUrl={getStartupUrl} />
      </main>
      <CookiesBanner />
      <Footer />
    </div>
  )
}
