import { useTranslation } from 'react-i18next';

export default function Introduction(props={}) {
    const { t } = useTranslation();

    const goToSignUp = () => props.goToSignUp();

    return (
        <section className='framed-text introduction'>
            <span className='triangle shape-right'></span>
            <span className='triangle shape-left'></span>
            <div>
                <h2 className='section-title'>{t("home.proptechpedia_description_title")}</h2>
                <p className='introduction-text'>{t("home.proptechpedia_description")}</p>
                <button className='button-filled' onClick={goToSignUp}>{t("actions.sign_startup_up")}</button>
            </div>
        </section>
    )
}
