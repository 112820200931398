import { useTranslation } from 'react-i18next';

import { categories } from '../../../constants/categories'
import CategoryPreview from './categoryPreview';
import useContextFilters from '../../hooks/useContextFilters';

export default function FeaturedCategories(props={}) {
    const { t } = useTranslation();
    const { goToCategory } = useContextFilters(); 

    return (
        <section className='home-categories'>
            <h2 className='section-title'>{t("home.featured_categories")}</h2>
            <div className='categories-box'>
                { categories.filter((category) => category.featured).map(category => <CategoryPreview filterBoard={goToCategory} name={category.name} key={category.id} image={category.image} id={category.id} />) }
            </div>
            <button className='action' onClick={props.goToCategories}>{t("categories.discover")}</button>
        </section>
    )
}
