import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import FirstFold from './firstFold/firstFold';
import FeaturedCompanies from './featuredCompanies/featuredCompanies'
import Introduction from './introduction/introduction';
import FeaturedCategories from './featuredCategories/featuredCategories';
import Subscribe from '../subscribe/subscribe';
import useContextFilters from '../hooks/useContextFilters';

export default function Home(props = {}) {
  const { t } = useTranslation();
  const { goToSearch } = useContextFilters();

  const goToSignUp = () => props.routerTo('/registro');
  const goToCategories = () => props.routerTo('/categorias');

  return (
    <section id='home'>
      <FirstFold goToSearch={goToSearch} />
      <FeaturedCompanies routerTo={props.routerTo} getStartupUrl={props.getStartupUrl} />
      <Introduction goToSignUp={goToSignUp} />
      <FeaturedCategories goToCategories={goToCategories} />
      <section className='extra-info'>
        <div className='extra-info-content'>
          <p>{t('home.registration_cta')}</p>
          <button className='button-filled' onClick={() => props.routerTo('/registro')} >{t('actions.sign_startup_up')}</button>
        </div>
        <div className='extra-info-image'>
          <Image src='/svg/p-image.svg' alt='' width={'320rem'} height={'350rem'} layout={'responsive'}/>
        </div>
      </section>
      <Subscribe />
    </section>
  )
}
