import { useTranslation } from 'react-i18next';

import Image from 'next/image';

import AutocompleteSearcher from './autocompleteSearcher';
import useContextFilters from '../../hooks/useContextFilters';

export default function FirstFold(props = {}) {
  const { t } = useTranslation();
  const { deleteContextFilters } = useContextFilters();

  const goToSearch = (startup) => {
    deleteContextFilters();
    props.goToSearch(startup);
  }

  const imageKitLoader = ({ src }) => {
    if(src[0] === "/") src = src.slice(1);
    let urlEndpoint = "https://ik.imagekit.io/m15publf1";
    if(urlEndpoint[urlEndpoint.length-1] === "/") urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
    return `${urlEndpoint}/${src}`
  }

  return (
    <section className='firstfold'>
      <div className='welcome-background-image'>
        <Image 
        src={'/welcome-background_cYf_E_JGjA.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1664889200174'} 
        priority 
        loader={imageKitLoader}
        layout='fill' 
        objectFit='cover' 
        objectPosition={'center'} 
        alt={''} />
      </div>
      <div className='firstfold-content'>
        <h1>{t("home.welcome_text")}</h1>
        <span>
          <AutocompleteSearcher goToSearch={goToSearch} />
        </span>
      </div>
    </section>
  )
}
