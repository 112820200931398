import Image from "next/image"

export default function CategoryPreview(props = {}) {
    return (
        <div className="category-preview" onClick={() => props.filterBoard({ id: props.id, name: props.name })} >
            <div className="category-image">
                <Image src={props.image} width={'100%'} height={'100%'} layout={'responsive'} alt={''} />
            </div>
            <div className="category-wrapper">
                <p className="category-name">
                    {props.name}
                </p>
            </div>
        </div>
    )
}
