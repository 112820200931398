import { useState } from 'react';

import { categories } from '../../constants/categories';
import { subcategories } from '../../constants/subcategories';

function useGetCategorization() {
    const [categoryObject, setCategoryObject] = useState();
    const [subcategoryObject, setSubcategoryObject] = useState();

    const getCategorization = (categoryName, subcategoryName) => {
        if (categoryName) {
            const categoryData = categories.filter(category => {
                return category.name.toLowerCase() === categoryName.toLowerCase()
            });

            if (categoryData[0]) {
                setCategoryObject(categoryData[0]);
            } else {
                setCategoryObject(null);
            } 

        }

        if (subcategoryName) {
            const subcategoryData = subcategories.filter(subcategory => {
                return subcategory.name.toLowerCase() === subcategoryName.toLowerCase()
            });
            
            if (subcategoryData[0]) {
                setSubcategoryObject(subcategoryData[0]);
            } else {
                setSubcategoryObject(null);
            }
        }
    }

    return { category: categoryObject, subcategory: subcategoryObject, getCategorization: getCategorization };
}

export default useGetCategorization;