import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '../../../services/api';

export default function AutocompleteSearcher(props = {}) {
  const [startup, setStartup] = useState("");
  const [predictedStartups, setPredictedStartups] = useState([]);
  let currentFocus = -1;
  const { t } = useTranslation();

  const goToSearch = () => {
    const searcher = document.getElementById('home-search-input');
    dataLayer.push({
      event: 'search',
      searchTerm: `${searcher.value}`,
    });
    props.goToSearch(searcher.value);
  }

  const handleSearcher = async (event) => {
    const value = event.target.value;
    setStartup(value);
  }

  useEffect(() => {
    let isMounted = true;
    
    const handlePrediction = async () => {
      const wrapper = document.getElementsByClassName('search-input-wrapper')[0];
      if (startup.length > 0) {
        const requestFilters = { name: startup.toLowerCase() };
        const [newStartups, apiError] = await Api.getStartups(requestFilters);

        if (apiError) {
          return;
        }

        if (newStartups.length > 0) {
          const newPredictedStartups = newStartups.slice(0, 5);
          setPredictedStartups([...newPredictedStartups]);
          wrapper.classList.toggle('search-input-wrapper-autocomplete-active', true);
        } else {
          setPredictedStartups([]);
          wrapper.classList.remove('search-input-wrapper-autocomplete-active');
        }
      } else {
        setPredictedStartups([]);
        wrapper.classList.remove('search-input-wrapper-autocomplete-active');
      }

      const searcher = document.getElementById('home-search-input');
      if (searcher && searcher.value.length < 1) {
        setPredictedStartups([]);
        wrapper.classList.remove('search-input-wrapper-autocomplete-active');
      }
    }

    if (isMounted) {
      handlePrediction();
    }

    return () => {
      isMounted = false;
    }
  }, [startup])


  const keyDownSelection = (event) => {
    if (event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 13) {
      event.preventDefault();
    }

    const startupList = document.getElementsByClassName('autocomplete-item');
    if (event.keyCode == 40 && currentFocus < startupList.length - 1) {
      currentFocus++;
      selectOption(startupList, currentFocus);
    } else if (event.keyCode == 38 && currentFocus >= 1) {
      currentFocus--;
      selectOption(startupList, currentFocus);
    } else if (event.keyCode == 13) {
      if (currentFocus > -1) {
        startupList[currentFocus].click();
      } else {
        goToSearch()
      }
    }
  }

  const selectOption = (elements, currentFocus) => {
    const searcher = document.getElementById('home-search-input');
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('autocomplete-active');
    }
    elements[currentFocus].classList.add('autocomplete-active');
    searcher.value = elements[currentFocus].innerText;
  }

  const handlePredictionClick = (event) => {
    const prediction = event.target.innerText;
    dataLayer.push({
      event: 'search',
      searchTerm: `${prediction}`,
    });
    props.goToSearch(prediction);
  }

  const toggleResultsList = (bool) => {
    const autocompleteList = document.getElementById('autocomplete-list');
    const wrapper = document.getElementsByClassName('search-input-wrapper')[0];
    autocompleteList.classList.toggle('display-none', bool);
    if (startup.length < 1) {
      wrapper.classList.remove('search-input-wrapper-autocomplete-active');
    } else {
      wrapper.classList.toggle('search-input-wrapper-autocomplete-active', !bool);
    }

  }

  return (
    <div className='autocomplete-wrapper' onKeyDown={keyDownSelection} onBlur={() => toggleResultsList(true)} onFocus={() => toggleResultsList(false)}>
      <div className='search-input-wrapper'>
        <input className='search-input' id='home-search-input' autoComplete='off' placeholder={`${t("home.search_prompt")}`} onChange={handleSearcher} />
        <button className='button-filled' onClick={goToSearch}>{t("home.search_button")}</button>
      </div>
      <ul className='autocomplete-items' id='autocomplete-list'>
        {startup.length > 0 && predictedStartups.map(startup => {
          return <li onClick={handlePredictionClick} onMouseDown={handlePredictionClick} key={startup.id} className={"autocomplete-item"}>{startup.name}</li>
        })}
      </ul>
    </div>
  )
}
