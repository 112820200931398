import { useTranslation } from 'react-i18next';

function useFormValidationRules() {
    const descriptionMaxLength = 450;
    const { t } = useTranslation();

    const validationRules = {
        email: {
            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: t('forms.errors.format.email')
        },
        password: {
            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            message: t('forms.errors.password')
        },
        url: {
            value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            message: t('forms.errors.format.url')
        },
        phoneNumber: {
            value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            message: t('forms.errors.format.generic')
        },
        year: {
            min: 2000,
            max: new Date().getFullYear(),
            message: t('forms.errors.year_limit')
        },
        requiredField: {
            value: true,
            message: t('forms.errors.required')
        },
        startupDescriptionLength: {
            value: descriptionMaxLength,
            message: t('forms.errors.limit_characters')
        }
    }

    return { validationRules: validationRules, descriptionMaxLength: descriptionMaxLength };
}

export default useFormValidationRules;