import { useAppContext } from '../../context/state';
import useNextRouterHook from "../../hooks/useNextRouterHook";

function useContextFilters() {
    const context = useAppContext();
    const routerTo = useNextRouterHook();

    const paramFormatter = (filterValue) => {
        if (filterValue) {
            return encodeURIComponent(filterValue.toLowerCase().trim().replaceAll(' ', '-'));
        }
    }

    const goToCategory = (category) => {
        context.setSharedState({ ...context.sharedState, filters: { category: `${category?.id}` } });
        const categoryParam = paramFormatter(category?.name);
        routerTo(`/${categoryParam}`);
    }

    const goToSubcategory = (category, subcategory) => {
        context.setSharedState({
            ...context.sharedState, filters: {
                category: `${category?.id}`, subcategory: `${subcategory?.id}`
            }
        });
        const categoryParam = paramFormatter(category?.name);
        const subcategoryParam = paramFormatter(subcategory?.name);
        routerTo(`/${categoryParam}/${subcategoryParam}`);
    }

    const goToSearch = (inputValue) => {
        if (inputValue.length > 0) {
            const name = inputValue.toLowerCase();
            context.setSharedState({ ...context.sharedState, filters: { name: `${name}` } });
            const nameParam = paramFormatter(inputValue);
            routerTo(`/empresas?name=${nameParam}`);
        } else {
            routerTo('/empresas');
        }
    }

    const deleteContextFilters = () => {
        delete context.sharedState.filters;
    }

    return {
        goToCategory: goToCategory, goToSubcategory: goToSubcategory,
        goToSearch: goToSearch, deleteContextFilters: deleteContextFilters,
        paramFormatter: paramFormatter
    };
}

export default useContextFilters;