import { useState, useEffect } from 'react';

import Image from 'next/image';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@emotion/react';
import Snackbar from '@mui/material/Snackbar';

import Api from '../../../services/api';
import Startup from './startup';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../constants/muiThemeBreakpoints';
import useSnackbarHook from '../../hooks/useSnackbarHook';

function FeaturedCompanies(props = {}) {
  const [featuredStartups, setFeaturedStartups] = useState([]);
  const { Alert, alertState, setAlertState, handleError } = useSnackbarHook();
  const { t } = useTranslation();

  useEffect(async () => {
    const [newFeaturedStartups, apiError] = await Api.getStartups({}, true);

    if (apiError) {
      handleError(apiError);
      return false;
    }

    if (typeof window !== 'undefined') {
      if (screen.width <= 768) {
        setFeaturedStartups(newFeaturedStartups.slice(0, 4));
      } else {
        setFeaturedStartups(newFeaturedStartups.slice(0, 9));
      }
    }
  }, []);

  return (
    <section className='featured-companies-wrapper'>
      <h2 className='section-title'>{t('home.featured_startups')}</h2>
      <ThemeProvider theme={theme}>
        <Grid container className='container'>
          <Grid container>
            {featuredStartups && featuredStartups.map((startup, index) => <Startup key={index} info={startup} getStartupUrl={props.getStartupUrl} routerTo={props.routerTo} />)}
          </Grid>
        </Grid>
      </ThemeProvider>
      <Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
        <Alert severity={alertState.severity} sx={{ width: '100%' }}>
          {alertState.message}
        </Alert>
      </Snackbar>
      <div className='action-area'>
        <div className='circle' onClick={() => props.routerTo("/empresas")}>
          <div className='featured-company-arrow'>
            <Image src='/svg/long-arrow.svg' width={'13rem'} height={'6rem'} layout='responsive' alt="" />
          </div>
          <p>{t('home.show_more')}</p>
        </div>
      </div>
    </section>
  )
}

export default FeaturedCompanies;
